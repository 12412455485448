import React from 'react';

function SkeletonComponent() {
   
    return (
        <>
           <div class="px-20 py-10 flex flex-row gap-x-4">
                <div class="animate-pulse space-y-2 w-1/3">
                    <div class="bg-gray-200 h-48"></div>
                    <div class="flex-1 space-y-2">
                        <div class="h-6 bg-gray-200 full"></div>
                        <div class="h-6 bg-gray-200 w-2/3"></div>
                    </div>
                </div>
                <div class="animate-pulse space-y-2 w-1/3">
                    <div class="bg-gray-200 h-48"></div>
                    <div class="flex-1 space-y-2">
                        <div class="h-6 bg-gray-200 full"></div>
                        <div class="h-6 bg-gray-200 w-2/3"></div>
                    </div>
                </div>
                <div class="animate-pulse space-y-2 w-1/3">
                    <div class="bg-gray-200 h-48"></div>
                    <div class="flex-1 space-y-2">
                        <div class="h-6 bg-gray-200 full"></div>
                        <div class="h-6 bg-gray-200 w-2/3"></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SkeletonComponent