import React, { useState, useEffect } from 'react';
import { CTAComponent } from "../components/"
import {urls} from '../utils/constants'
import axios from "axios"
import { useParams } from 'react-router-dom';


function ProjectDetails() {
    const [project, setProject] = useState(null);
    const { documentId } = useParams();

    useEffect(() => {
        const fetchProject = async () => {
            try {
                const response = await axios(`${urls.STRAPI_URL}projects/${documentId}?populate=banner`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    withCredentials: true,
                    credentials: 'same-origin',
                });
                setProject(response.data.data);
            } catch (error) {
                console.log(error);
            }
        };

        fetchProject();
    }, [documentId]);

    
    return (
        <> 
            { project ? 
                <>
                    {/* PROJECT TITLE */}
                    <div className="mx-custom-112 lg:block hidden">
                        <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">
                            {project.title}
                        </h1>
                    </div>
                    <div className="mx-4 lg:hidden sm:block">
                        <h1 className="lg:text-custom-64 text-4xl lg:leading-custom-72 custom-leading-44 font-semibold lg:my-24 my-12 lg:w-3/4">{project ? project.title : 'Loading...'}</h1>
                    </div>

                    <div className="w-full img-hero lg:h-custom-516 h-custom-250 border" style={{backgroundImage: `url(${urls.STRAPI_UPLOAD + project.banner.url})`}}></div>

                    {/* CONTENT */}
                    <div className="lg:mx-custom-112 px-4">
                        <div className="flex lg:flex-row flex-col-reverse">
                            <div className="basis-3/4 lg:py-24 sm:pt-12 lg:px-custom-104">
                                {project.content && project.content.map((item, index) => {
                                    switch (item.type) {
                                        case 'heading':
                                            return (
                                                <h3 className="text-custom-32 font-semibold my-6">
                                                    {item.children.map((child, childIndex) => (
                                                        child.text
                                                    ))}
                                                </h3>
                                            );
                                        case 'paragraph':
                                            return (
                                                <p className="text-lg text-custom-paragraph my-4">
                                                    {item.children.map((child, childIndex) => (
                                                        child.text
                                                    ))}
                                                </p>
                                            );
                                        default:
                                            return null;
                                    }
                                })}
                            </div>
                            <div className="basis-1/4 relative lg:py-24 py-12">
                                <div className="lg:w-72 w-full border bg-custom-card p-6 rounded-2xl">
                                    <div className="flex flex-col mb-6">
                                        <span className="text-base text-custom-paragraph">Industry</span>
                                        <span className="text-xl font-semibold">Education</span>
                                    </div>
                                    <div className="flex flex-col mb-6">
                                        <span className="text-base text-custom-paragraph">Timeline</span>
                                        <span className="text-xl font-semibold">2023</span>
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="text-base text-custom-paragraph">Solutions</span>
                                        <span className="text-xl font-semibold">Professional Services</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='py-16'>
                            <h3 className="lg:text-4xl text-2xl font-bold mb-10">Read more our customer stories</h3>
                            <div className="flex lg:flex-row flex-col lg:space-x-4 lg:space-y-0 space-y-4 lg:mb-24">
                                <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard4.png'})`}}>
                                    <div className="flex flex-col justify-between h-full">
                                        <img className="w-36" src={process.env.PUBLIC_URL + '/img/blog/tereos.png'} />
                                        <h1 className="lg:text-3xl text-base font-semibold text-white">Speeding Up Knowledge, PT. British Council Indonesia’s Fiber Optic Makeover</h1>
                                    </div>
                                </div>
                                <div className="basis-1/2 border rounded-lg p-3 bg-center" style={{height: '296px', backgroundImage: `url(${process.env.PUBLIC_URL + '/img/blog/imagecard3.png'})`}}>
                                    <div className="flex flex-col justify-between h-full">
                                        <img className="w-36" src={process.env.PUBLIC_URL + '/img/blog/kalbe.png'} />
                                        <h1 className="lg:text-3xl text-base font-semibold text-white">Fortigate 200F: The Secret Ingredient to PT. Tereos FKS Indonesia’s Digital Success</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
                :
                'Loading ... '
            } 
            
            <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
        </>
    )
}

export default ProjectDetails