import React, {Component} from "react"
import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom"
import {NavbarComponent, Navbar, FooterComponent} from "./components"
import Blog from "./pages/Blog"
import BlogDetails from "./pages/BlogDetails"
import Home from "./pages/Home"
import Project from "./pages/Project"

import ProjectDetails from "./pages/ProjectDetails"

// import ProjectDetailsPage1 from "./pages/ProjectDetailsPage1"
// import ProjectDetailsPage2 from "./pages/ProjectDetailsPage2"
// import ProjectDetailsPage3 from "./pages/ProjectDetailsPage3"
// import ProjectDetailsPage4 from "./pages/ProjectDetailsPage4"

import Contact from "./pages/Contact"
import About from "./pages/About"

import IoTSolution from "./pages/Solutions/IoTSolution"
import DataCenterSolution from "./pages/Solutions/DataCenterSolution"
import SecuritySolution from "./pages/Solutions/SecuritySolution"
import NetworkSolution from "./pages/Solutions/NetworkSolution"
import SolutionDetails from "./pages/Solutions/SolutionDetails"

import Chainsaw from "./pages/Products/Chainsaw"
import ChainsawDetail from "./pages/Products/ChainsawDetail"
import Error404 from "./pages/Error404"
import TermConditions from "./pages/TermConditions"
import PrivacyPolicies from "./pages/PrivacyPolicies"
import SecurityPolicy from "./pages/SecurityPolicy"

import Career from "./pages/Career"
import CareerDetails from "./pages/CareerDetails"

export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <NavbarComponent />
          <main>
            <Routes>
              <Route path="/" element={<Home />} exact />
              <Route path="/blog" element={<Blog />} exact />
              <Route path="/blog/:slug/:documentId" element={<BlogDetails />} />
              <Route path="/project" element={<Project />} exact />

              {/* <Route path="/project/British-Council-Indonesia" element={<ProjectDetailsPage1 />} exact />
              <Route path="/project/Tereos-FKS-Indonesia" element={<ProjectDetailsPage2 />} exact />
              <Route path="/project/Politeknik-STIA-LAN-Jakarta" element={<ProjectDetailsPage3 />} exact />
              <Route path="/project/Saka-Farma-Laboratories" element={<ProjectDetailsPage4 />} exact /> */}
              <Route path="/project/:slug/:documentId" element={<ProjectDetails />} exact />
              

              <Route path="/contact" element={<Contact />} exact />
              <Route path="/about" element={<About />} exact />
              
              <Route path="/solution/IoTSolution" element={<IoTSolution />} />
              <Route path="/solution/NetworkSolution" element={<NetworkSolution />} />
              <Route path="/solution/SecuritySolution" element={<SecuritySolution />} />
              <Route path="/solution/DataCenterSolution" element={<DataCenterSolution />} />
              <Route path="/solution/:solutionId" element={<SolutionDetails />} />

              <Route path="/product/chainway" element={<Chainsaw />} />
              <Route path="/product/chainway/1" element={<ChainsawDetail />} />
              <Route path="/term" element={<TermConditions />} />
              <Route path="/policies" element={<PrivacyPolicies /> } />
              <Route path="/security_policy" element={<SecurityPolicy /> } />

              <Route path="/career" element={<Career />} exact />
              <Route path="/career/details" element={<CareerDetails />} />

              {/* <Route path="/product/chainway/:productId" element={<ChainsawDetail />} /> */}
              
              <Route path="*" element={<Error404 />} />
            </Routes>
          </main>
        <FooterComponent />
      </BrowserRouter>
    )
  }
}