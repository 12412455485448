import React from 'react';
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import {urls} from '../../utils/constants' 

const titleProject = {
    color: "var(--gray-900, #101828) !important",
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: "600",
    lineHeight: "32px",
    textDecoration: "none",
}
const ImageProject = styled.div`
    height: 296px;
    border-radius: 12px;
    background-size: cover;
    background-position: center;
    margin-bottom: 24px;
    ${props => setBackground(props)}
`
const setBackground = (props) => {
    console.log(props.background)
    return css`
        background-image: url('${props.background}');
    `
}

function ProjectCard (props) {
    return (
        <>
            <section className="row">
                {props.data.data && props.data.data.map(d => (
                    <div className="our-projects col-md-6 mb-16" key={d.id}>
                        <Link to={`/project/${d.slug}/${d.documentId}`} >
                            <ImageProject background={urls.STRAPI_UPLOAD + d.banner.url}/>
                            <p style={titleProject}>{d.title}</p>
                        </Link>
                    </div>
                ))}
            </section>
        </>
    )
}
export default ProjectCard;