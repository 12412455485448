import React, {Component} from "react"
import { ProjectComponent, ServiceComponent, TestimoniComponent, TechnologyPartnerComponent, CTAComponent } from "../components"
import {urls} from '../utils/constants' 
import axios from "axios"
import { Link } from "react-router-dom";

export default class Home extends Component {
    constructor(props) {
        super(props)
        
        this.state = {
            dataCompanyLogo: [],
            dataBrandNusa: [],
            dataTestimonial: [],
        }
    }
    aboutImage1 = process.env.PUBLIC_URL + '/img/additional1.png'
    aboutImage2 = process.env.PUBLIC_URL + '/img/additional2.png'

    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});

        if (document.getElementsByClassName("link-to-gsap").length === 0){
            const gsap_script = document.createElement("script");
            gsap_script.classList.add("link-to-gsap");
            gsap_script.src = urls.GSAP_URL;
            gsap_script.integrity = "sha256-5RGBQKFeXbtHHxnAaBa8+kQXCHi9j+Ct6Awkt6mI2Lo="
            gsap_script.crossOrigin = "anonymous"
            gsap_script.async = false;
            console.log(gsap_script)
            document.body.appendChild(gsap_script);
        }

        if (document.getElementsByClassName("link-to-scrolltrigger").length === 0){
            const scroll_trigger = document.createElement("script");
            scroll_trigger.classList.add("link-to-scrolltrigger");
            scroll_trigger.src = urls.SCROLL_TRIGGER_SCRIPT;
            scroll_trigger.integrity = "sha256-BotszkugxFYh5cKUfrzD9nAakMhBzk8hDJNfAEehnq0="
            scroll_trigger.crossOrigin = "anonymous"
            scroll_trigger.async = false;
            console.log(scroll_trigger)
            document.body.appendChild(scroll_trigger);
        }

        axios(urls.API_URL+'dataCompanyLogo', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
        }).then(response => {
            console.log(response.data)
            this.setState({ dataCompanyLogo: response.data })
        }).catch(function (error) {
            console.log(error)
        })


        axios(urls.API_URL+'dataBrandNusa', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
        }).then(response => {
            console.log(response.data)
            this.setState({ dataBrandNusa: response.data })
        }).catch(function (error) {
            console.log(error)
        })

        axios(urls.API_URL+'dataTestimonial', {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
            withCredentials: true,
            credentials: 'same-origin',
            }).then(response => {
                console.log(response.data)
                this.setState({ dataTestimonial: response.data })
            }).catch(function (error) {
                console.log(error)
            })
    }

    redirectToContact() {
        window.location.href = "/contact";
    };

    render() {
        const { dataCompanyLogo, dataBrandNusa, dataTestimonial } = this.state;
        return (
            <>
                <div id="header">
                    <section id="typography">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-8">
                                <h1 style={{fontWeight: 600}}>We provide truly<br /> prominent <br className="mob" /><span style={{color: "#3B82F6"}}>IT Solutions</span></h1>
                            </div>
                            <div className="col-md-4" id="typography-tag1">
                                <p id="typography-tag2">Highly Tailored IT Design,<br /> Management & Support Services.</p>
                                <button className="btn btn-primary-gfs mob" onClick={this.redirectToContact}>Contact Us</button>
                            </div>
                        </div>
                    </div>
                    </section>
                    <section id="banner" />
                </div>
                <section id="partner">
                    <h2 className="big-title">Trusted by<br className="mob" /> 50+ Companies</h2>
                    <div class="slider">
                        <div class="slide-track">
                            {dataCompanyLogo.map(logo => (
                                <div class="slide">
                                    <img 
                                        style={{marginRight:'92px'}}
                                        key={logo.id}
                                        alt={`Logo ${logo.id}`}
                                        className="deskt" 
                                        src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                            ))}

                            {dataCompanyLogo.map(logo => (
                                <div class="slide">
                                <img 
                                    style={{marginRight:'92px'}}
                                    key={logo.id}
                                    alt={`Logo ${logo.id}`}
                                    className="mob" 
                                    src={process.env.PUBLIC_URL + logo.path} />
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
                <section id="additional-service-1">
                    <div className="container">
                        <div className="row" id="text-about">
                            <div className="col-md-6" style={{marginBottom: "64px"}}>
                                <h2 className="big-title">We help you </h2>
                                <h2 className="big-title">to grow your business</h2>
                                <h2 className="big-title"><span style={{color: "#3B82F6", fontStyle: "italic"}}>exponentially.</span></h2>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                        <div className="row" id="additional-services-list">
                            <div className="col-md-6"> 
                                <div className="additional-service-image" style={{backgroundImage: `url(${this.aboutImage1})`, backgroundSize: 'cover', width: "100%"}}></div>
                                <p className="small-title">More than 10 years experience in IT professionals, we provide solutions for your business</p>
                                <Link to="/about" className="link-additional-about">About us</Link>
                            </div>
                            <div className="col-md-6">
                                <div className="additional-service-image" style={{backgroundImage: `url(${this.aboutImage2})`, backgroundSize: 'cover', width: "100%"}}></div>
                                <p className="small-title">We provide professional resources that carry out projects on time according to the target</p>
                                <Link to="/notfound" className="link-additional-project">Our projects</Link>
                            </div>
                        </div>
                    </div>
                </section>

                <ServiceComponent />
                {/* project section */}
                <ProjectComponent />
                {/* end project section */}
                <TestimoniComponent data={dataTestimonial}/>
                <TechnologyPartnerComponent data={dataBrandNusa}/>
                <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />
            </>
        )
    }
}