import React, {Component} from "react"
import {CTAComponent} from "../components/"
import ProjectCard from "../components/ProjectComponent/ProjectCard"
import {urls} from '../utils/constants' 
import axios from "axios"
import styled, { css } from "styled-components";

const RingComponent = styled.div`
    width: 1200px;
    height: 1200px;
    flex-shrink: 0;
    border-radius: 1200px;
    border: 30px solid #3B82F6;
    filter: blur(50px);
    position: absolute;
    right: -500px;
    top: -600px;
    
`;

export default class Project extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        }
    }
    componentDidMount() {
        window.scrollTo({top:0, lef: 0, behavior: "instant"});

        // axios(urls.API_URL+'dataProjects', {
        // method: 'GET',
        // headers: {
        //     'Content-Type': 'application/json',
        // },
        // withCredentials: true,
        // credentials: 'same-origin',
        // }).then(response => {
        //     console.log(response.data)
        //     this.setState({ data: response.data })
        // }).catch(function (error) {
        //     console.log(error)
        // })

        axios(urls.STRAPI_URL+'projects?populate=banner', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
        credentials: 'same-origin',
        }).then(response => {
            this.setState({ data: response.data })
        }).catch(function (error) {
            console.log(error)
        })



    }
    render() {
        const data = this.state.data
        return (
            <>
                <RingComponent/>
                
                <div className="mx-32 ">
                    <div className="my-16 w-10/12" >
                        <h1 className="text-5xl font-semibold">Explore how we plays a crucial role in ensuring the functionality of business worldwide.</h1>
                    </div>
                    <ProjectCard data={data}/>              
                </div>
                <CTAComponent tagline="We run all kinds of IT services that vow your success" title_button="Contact Us" />

            </>
        )
    }
}
